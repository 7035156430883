function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "\n    text-decoration: underline;\n    text-underline-offset: ",
        ";\n    color: ",
        ";\n\n    svg {\n        color: ",
        ";\n    }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _taggedTemplateLiteral([
        "\n                    color: ",
        ";\n\n                    svg {\n                        color: ",
        ";\n                    }\n                "
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _taggedTemplateLiteral([
        "\n                    color: ",
        ";\n\n                    svg {\n                        color: ",
        ";\n                    }\n                "
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _taggedTemplateLiteral([
        "\n                    color: ",
        ";\n\n                    svg {\n                        color: ",
        ";\n                    }\n                "
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _taggedTemplateLiteral([
        "\n    ",
        "\n    font-size: ",
        ";\n    display: inline-flex;\n    justify-content: flex-start;\n    align-items: center;\n    color: ",
        ";\n    transition: color 50ms ease;\n\n    svg {\n        height: ",
        ";\n        width: ",
        ";\n        margin-right: ",
        ";\n        color: ",
        ";\n        transition: fill 50ms ease;\n    }\n\n    ",
        "\n\n    &.focus-visible {\n        ",
        "\n    }\n\n    @media (hover: hover) {\n        &:hover {\n            ",
        "\n        }\n    }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import styled, { css } from "styled-components";
import { rem } from "polished";
var HoverStyles = css(_templateObject(), rem(4), function(param) {
    var theme = param.theme, format = param.format;
    return format === "negative" ? theme.palette.Red : theme.palette.White;
}, function(param) {
    var theme = param.theme, format = param.format;
    return format === "negative" ? theme.palette.Red : theme.palette.White;
});
var TextButton = styled.button.withConfig({
    componentId: "sc-1c5adbc0-0"
})(_templateObject4(), function(param) {
    var theme = param.theme;
    return theme.typography.FontMedium;
}, rem(13), function(param) {
    var theme = param.theme, format = param.format;
    return format === "secondary" ? theme.palette.LightGrey1 : theme.palette.White;
}, rem(16), rem(16), rem(4), function(param) {
    var theme = param.theme, format = param.format;
    return format === "secondary" ? theme.palette.LightGrey1 : theme.palette.White;
}, function(param) {
    var format = param.format, theme = param.theme;
    switch(format){
        case "secondary":
            return css(_templateObject1(), theme.palette.LightGrey1, theme.palette.LightGrey1);
        case "negative":
            return css(_templateObject2(), theme.palette.Red, theme.palette.Red);
        default:
            return css(_templateObject3(), theme.palette.White, theme.palette.White);
    }
}, HoverStyles, HoverStyles);
export default TextButton;
