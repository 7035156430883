function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _arrayWithoutHoles(arr) {
    if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}
function _iterableToArray(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _nonIterableSpread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _toConsumableArray(arr) {
    return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}
function _unsupportedIterableToArray(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}
import { useEffect } from "react";
var useDetectClickOutside = function(param) {
    var elements = param.elements, enabled = param.enabled, _selectors = param.selectors, selectors = _selectors === void 0 ? [] : _selectors, _mobile = param.mobile, mobile = _mobile === void 0 ? false : _mobile, _swallowClicks = param.swallowClicks, swallowClicks = _swallowClicks === void 0 ? true : _swallowClicks, _escapeKey = param.escapeKey, escapeKey = _escapeKey === void 0 ? false : _escapeKey, onClickOutside = param.onClickOutside;
    useEffect(function() {
        if (enabled) {
            var clickEvent = mobile ? "touchstart" : "click";
            var handleClick = function(e) {
                var allElements = _toConsumableArray(elements);
                selectors.forEach(function(selector) {
                    var element = document.querySelector(selector);
                    if (element) {
                        allElements.push(element);
                    }
                });
                if (!allElements.some(function(element) {
                    return !!element && element.contains(e.target);
                })) {
                    if (swallowClicks) {
                        e.stopPropagation();
                        e.preventDefault();
                    }
                    window.removeEventListener(clickEvent, handleClick, {
                        capture: true
                    });
                    onClickOutside();
                }
            };
            window.addEventListener(clickEvent, handleClick, {
                capture: true
            });
            return function() {
                window.removeEventListener(clickEvent, handleClick, {
                    capture: true
                });
            };
        }
    }, [
        enabled,
        mobile,
        swallowClicks,
        onClickOutside
    ].concat(_toConsumableArray(elements), _toConsumableArray(selectors)));
    useEffect(function() {
        if (enabled && escapeKey) {
            var handleEscape = function(e) {
                if (e.key === "Escape") {
                    onClickOutside();
                    if (swallowClicks) {
                        e.stopPropagation();
                        e.preventDefault();
                    }
                }
            };
            document.addEventListener("keydown", handleEscape, {
                capture: true
            });
            return function() {
                document.removeEventListener("keydown", handleEscape, {
                    capture: true
                });
            };
        }
    }, [
        enabled,
        escapeKey,
        swallowClicks,
        onClickOutside
    ]);
};
export default useDetectClickOutside;
